<template>
    <div class="share-item" >
        <div class="code-img">
            <vue-qr 
                ref="Qrcode" 
                :text="getUserId ? userHost : (getToken ? host : '')" 
                :margin="6" 
                colorLight="#fff" 
                :size="160"
                :correctLevel="3"
            ></vue-qr>
        </div>
        <div v-if="isWx" class="download-msg">
            长按图片即可保存
        </div>
        <div 
            v-else
            class="download"
            @click="downloadImg" 
        >保存二维码</div>

    </div>
</template>

<script>
    import { getHotUrl, getStorage } from '@/utils/common'
    import vueQr from 'vue-qr'
    import mixins from '@/mixins/downloadImag'
    export default {
        mixins: [ mixins ],
        data() {
            return {
                host: '',
                userHost: '',
                isWx: false
            }
        },
        created() {
            // 判断是不是微信环境
            this.isWx = window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger'
        },
        computed: {
            getUserId() {
                const type = getStorage('systemType')
                const userId = getStorage('userId')
                this.getUserHost(userId, type)
                return userId
            },
            getToken() {
                const token = getStorage('token')
                this.getTokenHost(token)
                return token
            }
        },
        components: {
            vueQr
        },
        methods: {
            getUserHost(userId, type) {
                this.userHost = `${getHotUrl(this.$route)}/mobileApplyExperience?userId=${userId}&system_type=${type}`
            },
            getTokenHost(token) {
                this.host = `${getHotUrl(this.$route)}/mobileApplyExperience?token=${token}`
            },
            // 下载二维码图片
            downloadImg () {
                const Qrcode = this.$refs.Qrcode
                const userAgent = navigator.userAgent.toLowerCase();//获取UA信息
                if(userAgent.indexOf('deviceios') != -1) {
                    //ios
                    window.webkit.messageHandlers.saveQrCodeImage.postMessage({ base64: Qrcode.$el.currentSrc})
                } else if(userAgent.indexOf('androidyuekehumini') != -1) {
                    // 安卓
                    window.sharemarket.savePictureToAlbum(Qrcode.$el.currentSrc)
                } else {
                    this.downloadFile('分享二维码', Qrcode.$el.currentSrc)
                }
            }
        }
    }   
</script>

<style lang="scss" scoped>
.share-item{
    .code-img{
        width: r(320);
        font-size: 0;
        text-align: center;
        margin: auto;
        margin-top: r(48);
        box-shadow: 0px r(10) r(20) rgba(180, 76, 0, 0.3);
    }
    .download-msg{
        @include font(r(24),#FF962B,center);
        margin: auto;
        margin-top: r(24);
    }
    .download{
        width: r(172);
        height: r(40);
        border: r(2) solid #FF962B;
        border-radius: r(40);
        text-align: center;
        line-height: r(36);
        @include font(r(24),#FF962B,center);
        margin: auto;
        margin-top: r(24);
    }
}
</style>