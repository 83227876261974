<template>
    <div class="step-list">
        <div class="step-item">
            <img src="../../assets/image/mobile/step_two.png" />
            <div>
                <p>复制链接</p>
                <p>分享好友</p>
            </div>
        </div>
        <img class="step-icon" src="../../assets/image/mobile/step_icon.png" />
        <div class="step-item">
            <img src="../../assets/image/mobile/step_three.png" />
            <div>
                <p>好友充值</p>
                <p>平台消费</p>
            </div>
        </div>
        <img class="step-icon" src="../../assets/image/mobile/step_icon.png" />
        <div class="step-item">
            <img src="../../assets/image/mobile/step_four.png" />
            <div>
                <p>享受10%</p>
                <p>佣金分成</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'shareStep'
    }
</script>

<style lang="scss" scoped>
.step-list{
    @include flex(fle-start,space-between);
    width: 100%;
    border-bottom: r(2) solid rgba(255, 255, 255, 0.1);
    padding-bottom: r(48);
    .step-item{
        img{
            display: block;
            margin: auto;
            @include wh(r(84),r(84));
            margin-bottom: r(16)
        }
        p{
            @include font(r(24),#fff,center);
            line-height: r(36)
        }
    }
    .step-icon{
        @include wh(r(38),r(16));
        margin-top:r(34);
    }
}
</style>