<template>
    <div :class="['share-link-warp', !isShow && 'link-warp']">
        <div :class="['share-title', isShow && 'code']">邀请链接：</div>
        <div :class="['share-item', isShow && 'share-item-flex']">
            <div class="share-link">{{getUserId ? userHost : (getToken ? host : '')}}</div>
            <div class="share-but copy-mobile-link" :data-clipboard-text="getUserId ? userHost : (getToken ? host : '')">复制</div>
        </div>
    </div>
</template>

<script>
    import Clipboard from 'clipboard';
    import { getHotUrl, getStorage } from '@/utils/common'
    export default {
        props: {
            isShow: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                host: '',
                userHost: ''
            }
        },
        mounted() {
            // const clipboardCode = new Clipboard('.copy-code');
            // clipboardCode.on('success', () => {
            //     this.$toast('已复制到剪贴板！')
            // });
            const clipboard = new Clipboard('.copy-mobile-link');
            clipboard.on('success', ()=> {
                this.$toast('已复制到剪贴板！')
            });
            
        },
        computed: {
            getUserId() {
                const type = getStorage('systemType')
                const userId = getStorage('userId')
                this.getUserHost(userId, type)
                return userId
            },
            getToken() {
                const token = getStorage('token')
                this.getTokenHost(token)
                return token
            }
        },
        methods: {
            getUserHost(userId, type) {
                this.userHost = `${getHotUrl(this.$route)}/mobileApplyExperience?userId=${userId}&system_type=${type}`
            },
            getTokenHost(token) {
                this.host = `${getHotUrl(this.$route)}/mobileApplyExperience?token=${token}`
            },
        }
    }   
</script>

<style lang="scss" scoped>
.share-link-warp{
    &.link-warp{
        width: r(600);
        min-height: r(280);
        margin: auto;
        flex-wrap: wrap;
        background-size: 100% 100% !important;
        padding:0 r(36) 0 r(36);
        background: url('../../assets/image/mobile/share.png') no-repeat;
        padding-top: r(24);
    }
    
    .share-title{
        @include font(r(28),#fff);
        margin-bottom: r(26);
        opacity: 0.5;
        &.code{
            margin-top: r(26);
        }
    }
    .share-item{
        padding-bottom: r(25);
        &.share-item-flex{
            @include flex(center,space-between);
            .share-link{
                @include overflow(1);
            }
            .share-but{
                @include font(r(24),#fff,center);
                @include wh(r(100),r(40));
                line-height: r(32);
                margin: auto;
            }
        }
        .share-link{
            flex: 1;
            @include font(r(28),#fff);
            @include overflow(2);
        }
        .share-but{
            @include font(r(28),#fff,center);
            @include wh(r(140),r(60));
            line-height: r(55);
            border: 2px solid rgba(255, 255, 255, 0.5);
            border-radius: r(40);
            margin: auto;
            margin-top: r(20);
        }
    }
}
    
</style>