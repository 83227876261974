<template>
  <div :class="['home-warp',isClick?'is-click':'']">
    <div class="exit" @click="exitLogin" v-if="isExite">退出登录</div>
    <div class="rules" @click="lookRules">查看规则</div>
    <div class="teamwork_title">
      <img src="../../assets/image/mobile/teamwork_title.png" />
    </div>
    <p class="share-title">好友成功充值并消费 即可享受佣金分成</p>
    <div class="my-invite-content">
      <img class="teamwork_header" src="../../assets/image/mobile/teamwork_header.png" />
      <div class="invite-info">
        <div class="my-invite-header">我的邀请</div>
        <div class="my-invite-list">
          <ul>
            <li>
              <span>邀请获客</span>
              <span>{{recordInfo.invite_apply_submit_count}}</span>
            </li>
            <li>
              <span>待返佣金</span>
              <span>￥{{recordInfo.wait_money}}</span>
            </li>
            <li>
              <span>可提现佣金</span>
              <span>￥{{recordInfo.withdraw_money}}</span>
            </li>
          </ul>
          <div class="record" @click="lookDetail">查看明细</div>
          <shareImage/>
        </div>
      </div>
    </div>
    <div class="step-wrap">
      <div class="step-title">最新推荐返佣用户</div>
      <div class="scroll-header">
          <div>姓名</div>
          <div>电话</div>
          <div>预计分佣</div>
          <div>参与时间</div>
      </div>
      <vue-seamless-scroll :data="scrollList" class="seamless-warp" :class-option="classOption">
          <ul class="scroll-item">
              <li v-for="(item,index) in scrollList" :key="index">
                  <span>{{item.name}}</span>
                  <span>{{item.mobile}}</span>
                  <span>{{item.money}}</span>
                  <span>{{item.time}}</span>
              </li>
          </ul>
      </vue-seamless-scroll>
      <shareStep/>
      <shareLink isShow/>
    </div>
    
    <div class="share-msg">
        <div class="share-msg-content">
            <img :src="shareImg" />
            <p>专为新老客户量身定制的推荐返佣平台，只需推荐好友使用沃创云平台，该好友开通外呼系统、优选商机等产品，您即可获得丰厚佣金；推荐人数无上限，分佣总额无上限，详情请点击右上角“查看规则”。</p>
        </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import shareLink from '@/components/mobile/shareLink'
  import shareStep from '@/components/mobile/shareStep'
  import shareImage from '@/components/mobile/shareImage'
  import { validaMobile } from '@/utils/valida'
  import { systemType } from '@/utils/enum'
  import { setStorage, removeStroage } from '@/utils/common'
  import { Dialog } from 'vant';
  import vueSeamlessScroll from 'vue-seamless-scroll'
  export default {
    data() {
      return {
        isClick: false,
        shareImg: require('@/assets/image/share-msg-title.png'),
        scrollList: [
            {
                name: '李**',
                mobile: '158**316',
                money: '1630.00元',
                time: '1天前'
            },
            {
                name: '张**',
                mobile: '139**213',
                money: '830.69元',
                time: '2天前'
            },
            {
                name: '陈**',
                mobile: '159**323',
                money: '1536.56元',
                time: '1天前'
            },
            {
                name: '李*',
                mobile: '137**239',
                money: '932.61元',
                time: '3天前'
            },
            {
                name: '吴**',
                mobile: '139**132',
                money: '760.00元',
                time: '1天前'
            },
            {
                name: '叶**',
                mobile: '158**210',
                money: '1832.36元',
                time: '5天前'
            },
            {
                name: '叶**',
                mobile: '157**399',
                money: '1322.00元',
                time: '2天前'
            },
            {
                name: '黄*',
                mobile: '172**256',
                money: '822.63元',
                time: '3天前'
            },
            {
                name: '钟**',
                mobile: '159**329',
                money: '1323.03元',
                time: '3天前'
            },
            {
                name: '周**',
                mobile: '159**613',
                money: '310.00元',
                time: '3天前'
            },
            {
                name: '王**',
                mobile: '158**823',
                money: '520.03元',
                time: '3天前'
            },
            {
                name: '李**',
                mobile: '136**316',
                money: '1230.16元',
                time: '1天前'
            },
            {
                name: '朱**',
                mobile: '178**219',
                money: '337.00元',
                time: '2天前'
            },
            {
                name: '何**',
                mobile: '188**331',
                money: '1032.00元',
                time: '2天前'
            },
        ]
      }
    },
    computed: {
      ...mapState([ 'recordInfo', 'isExite' ]),
      classOption () {
          return {
              step: 0.2,
              limitMoveNum: this.scrollList.length,
              hoverStop: false,
          }
      }
    },
    components: {
      shareLink,
      shareStep,
      shareImage,
      vueSeamlessScroll
    },
    async created() {
      const { userId, system_type, token } = this.$route.query
      if(!validaMobile()) {
          //移动端
          const query = token ? { token } : { userId, system_type }
          this.$router.replace({ path: '/home', query })
          return
      }
      // token优选
      if(token) {
        removeStroage('userId')
        removeStroage('systemType')
        setStorage('token', token)
        this.$store.dispatch('getMyRecord')
        return
      }


      if(!userId) {
        this.$toast('用户id获取失败')
        return
      }
      if(!systemType.includes(system_type)){
        this.$toast('system_type类型错误')
        return
      }
      localStorage.clear()
      setStorage('userId', userId)
      setStorage('systemType', system_type)
      const result = await this.$store.dispatch('getToken', { userId })
      if(!result){
          this.isClick = true
      }
    },
    methods: {
      lookRules() {
        this.$router.push('mobileActivityRules')
      },
      lookDetail() {
          this.$router.push('mobileDetail')
      },
      // 退出登录
      exitLogin() {
        Dialog.confirm({
          title: '提示',
          message: '确定退出登录吗？',
          cancelButtonColor: '#697085',
          confirmButtonColor: '#3E6EF1'
        }).then(() => {
          this.$postHttp(this.$api.postExitLogin).then(res => {
            if(res.code == 1) {
              this.$toast('退出成功')
              localStorage.clear()
              setTimeout(() => {
                  this.$router.replace('/')
              }, 500);
            }
          })
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
.home-warp{
  background: url('../../assets/image/mobile/home_bg.png') no-repeat;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: r(84);
  min-height: 100vh;
  &.is-click{
      pointer-events: none;
  }
  .exit{
    position: absolute;
    left: 0;
    top: r(48);
    z-index: 5;
    @include wh(r(172),r(56));
    background: #FFFFFF;
    opacity: 0.8;
    border-radius: 0 r(30) r(30) 0;
    @include font(r(28),#F53C3C,center);
    line-height: r(56);
  }
  .rules{
    position: absolute;
    right: 0;
    top: r(48);
    z-index: 5;
    @include flex();
    @include wh(r(172),r(56));
    @include font(r(28),#FFFFFF);
    box-shadow: 0px r(10) r(20) rgba(188, 48, 48, 0.5);
    background: #F8AC29;
    border-radius: r(30) 0 0 r(30);
  }
  .teamwork_title{
    position: absolute;
    width: 100%;
    height: r(285);
    left: 0;
    top: r(138);
    img{
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .share-title{
    position: absolute;
    top: r(426);
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    @include font(r(24),#FFFFFF,center);
    @include wh(r(520),r(60));
    background: #D12724;
    border-radius: r(38);
    line-height: r(60);
  }
  .my-invite-content{
    
    margin-top: r(440);
    margin-bottom: r(64);
   
    .teamwork_header{
      display: block;
      width:100%;
      height: r(448);
    }
    .invite-info{
      margin: auto;
      width: r(648);
      .my-invite-header{
        width: 100%;
        height: r(96);
        @include font(r(36),#fff,center);
        line-height: r(96);
        background: #FF8788;
        border-radius: r(24);
      }
      .my-invite-list{
        margin: auto;
        width: r(600);
        padding-bottom: r(48);
        border-bottom-left-radius: r(24);
        border-bottom-right-radius: r(24);
        background: #fff;
        box-shadow: 0px r(20) r(40) rgba(151, 0, 0, 0.16);
        ul{
          padding: r(50) r(58) 0 r(58);
          li{
            @include flex();
            @include font(r(32),$main_font_color);
            margin-bottom: r(30);
            &:last-child{
              margin-bottom: r(60);
            }
            span{
              &:last-child{
                flex: 1;
                text-align: right;
                color: $main_font_color;
                font-weight: bold;
              }
            }
          }
        }
        .record{
          @include font(r(32),#fff,center);
          @include wh(r(400),r(80));
          line-height: r(80);
          box-shadow: 0px r(10) r(20) rgba(48, 67, 188, 0.1);
          border-radius: r(40);
          background: #F8AC29;
          margin: auto;
          background: -webkit-gradient(linear, left top, left bottom, from(#FFD13B), to(#FF8226));
          background: -o-linear-gradient(top, #FFD13B 0%, #FF8226 100%);
          background: linear-gradient(180deg, #FFD13B 0%, #FF8226 100%);
          -webkit-box-shadow: 0px r(6) r(12) rgba(255, 111, 0, 0.8);
                  box-shadow: 0px r(6) r(12) rgba(255, 111, 0, 0.8);
        }
      }
    }
    
  }
  ::v-deep .share-item{
    border-color: transparent;
  }
  .step-wrap{
    @include wh(r(600),r(824));
    margin: auto;
    background: url('../../assets/image/mobile/home_share.png') no-repeat;
    background-size: 100% 100%;
    padding: r(30) r(40) 0 r(40);
    .step-title{
      @include font(r(28),#fff,center);
      margin-bottom: r(12);
    }
    .scroll-header{
        @include wh(100%,r(56));
        @include flex();
        background: rgba(255, 255, 255, 0.8);
        @include font(r(20),#F53C3C,center);
        >div{
            width: 25%;
        }
    }
    .seamless-warp {
        height: r(224);
        overflow: hidden;
        margin-bottom: r(48);
        .scroll-item{
            li{
                height: r(56);
                @include flex();
                border-bottom: r(2) solid rgba(255, 255, 255, 1);
                @include font(r(20),rgba(255, 255, 255, 1),center);
                span{
                    width: 25%;
                    @include overflow(1);
                }
            }
        }
    }
  }
  .share-msg{
      @include wh(6.86rem,5.34rem);
      margin: auto;
      margin-top: .64rem;
      border-radius: .08rem;
      background: #FEE4DF;
      padding: .16rem;
      .share-msg-content{
          border-radius: .08rem;
          background: #fff;
          height: 100%;
          padding: .48rem .42rem .48rem .4rem;
          border: .02rem dashed rgba(251, 123, 96, 0.5);
          img{
              display: block;
              margin: auto;
              @include wh(2.47rem,.72rem);
          }
          p{
              @include font(.32rem,$main_font_color);
              line-height: .48rem;
              position: relative;
              margin-top: .46rem;
              &::after{
                  content: '';
                  position: absolute;
                  left: 50%;
                  transform: translateX(-50%);
                  top: -.24rem;
                  @include wh(1.2rem,.06rem);
                  background: #FC8465;
                  border-radius: .08rem;
              }
          }
      }
  }
}
</style>